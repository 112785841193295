import React from "react"
import { useLayoutContext } from "~layout/contexts/layout"
import Page from "~common/components/Page"
import { Box } from "~common/components/Primitives"
import ProductDetails from "~shop/components/ProductDetails"

const ShopPage = () => {
  const { headerHeight, availableHeight } = useLayoutContext()

  return (
    <Page
      seo={{
        title: "Dune Saga Tribute",
        description:
          "Celebrate your best memories with a unique astronomical poster, based on NASA ephemerides | Create your custom print!A very special poster to celebrate the great sci-fi saga Dune by Frank Herbert.",
      }}
    >
      <Page.Body
        sx={{
          mt: headerHeight,
          minHeight: availableHeight,
        }}
      >
        <ProductDetails initialProductId="dune_50x70cm">
          <ProductDetails.Heading>
            <ProductDetails.Title />
            <ProductDetails.Selector
              sx={{
                my: 4,
                width: ["100%", "100%", "100%", "auto"],
                display: "flex",
                flexDirection: ["row", "row", "row", "column"],
                justifyContent: [
                  "space-between",
                  "space-between",
                  "space-between",
                  "auto",
                ],
              }}
              productsMap={{
                dune_30x40cm: "small",
                dune_50x70cm: "medium",
                dune_61x91cm: "large",
              }}
            />
          </ProductDetails.Heading>
          <ProductDetails.Gallery>
            <ProductDetails.Image />
            <ProductDetails.ImageSelector />
          </ProductDetails.Gallery>
          <ProductDetails.Info>
            <ProductDetails.Title sx={{ display: ["none", "none", "block"] }} />
            <ProductDetails.Description sx={{ mt: [4] }} />
            <ProductDetails.Selector
              sx={{
                mt: 4,
                width: ["100%", "100%", "100%", "auto"],
                display: ["none", "none", "flex"],
                flexDirection: ["row", "row", "row", "column"],
                justifyContent: [
                  "space-between",
                  "space-between",
                  "space-between",
                  "auto",
                ],
              }}
              productsMap={{
                dune_30x40cm: "small",
                dune_50x70cm: "medium",
                dune_61x91cm: "large",
              }}
            />
            <Box as="ul" sx={{ mt: [4] }}>
              <ProductDetails.Feature icon="shipping">
                FREE WORLDWIDE SHIPPING (7-14 days)
              </ProductDetails.Feature>
              <ProductDetails.Feature icon="ruler">
                <ProductDetails.Size />
              </ProductDetails.Feature>
              <ProductDetails.Feature icon="paper">
                Printed on Enhanced Matte Paper
              </ProductDetails.Feature>
              <ProductDetails.Feature icon="eco">
                Toxin-free, Biodegradable, Water-based Ink
              </ProductDetails.Feature>
            </Box>
            <Box as="ul" sx={{ mt: [4] }}>
              <ProductDetails.Note>no frame included</ProductDetails.Note>
            </Box>
            <ProductDetails.PriceTag sx={{ mt: [4] }} />
            <ProductDetails.Cta
              sx={{ my: [4, 5], alignSelf: ["center", "center", "flex-start"] }}
            />
          </ProductDetails.Info>
        </ProductDetails>
      </Page.Body>
    </Page>
  )
}

export default ShopPage
